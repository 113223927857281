body {
  margin: 0;
  padding: 30px;
}

ul {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 20px 0px 20px 20px;
  }

  main {
    padding: 0px 20px;
  }

  .project-details-container img,
  .project-details-container video {
    max-height: 350px;
  }
}

/* Two-column layout for "Our Clients" section */
.clients-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Space between the columns */
  padding: 20px 0;
}

.clients-title {
  flex: 1;
  text-align: center;
}

.clients-title h2 {
  font-size: 24px;
  margin: 0;
}

/* Horizontal brands */
.scroll-container {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%; /* Full width of the scroll container */
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 128px,
    black calc(100% - 128px),
    transparent 100%
  );
}

.logo-list {
  display: flex;
  align-items: center;
  justify-content: start;
  animation: scroll 20s linear infinite;
  list-style: none;
  padding: 0;
  margin: 0;
}

.logo-list li {
  margin: 0 32px;
}

.logo-list img {
  max-width: none;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.project-details-container {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  padding-bottom: 48px;
}

.project-details-container .centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.project-details-container .image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.project-details-container .image-item {
  position: relative;
  display: flex;
  align-items: center;
}

.project-details-container img,
.project-details-container video {
  max-width: 300px;
  max-height: 400px;
  width: 300px;
  height: 400px;
  object-fit: cover;
  object-position: top right;
}

.project-details-container img.large-size,
.project-details-container video.large-size {
  max-width: 530px;
  max-height: 340px;
  width: 100%;
  height: 400px;
}

.project-details-container img.velvet-size,
.project-details-container video.velvet-size {
  max-width: 600px;
  max-height: 350px;
  width: 100%;
  height: 400px;
}

.image-item img,
.image-item video {
  cursor: pointer;
}

body.safari .project-details-container img.velvet-size,
body.safari .project-details-container video.velvet-size {
  max-height: 480px;
  height: 500px;
  transform: scale(0.75);
}

body.safari .project-details-container .image-item img.velvet-size,
body.safari .project-details-container .image-item video.velvet-size {
  margin-top: calc(-15px * 3.25);
}

@media only screen and (max-width: 600px) {
  .project-details-container img.large-size,
  .project-details-container video.large-size {
    max-width: 100%;
    max-height: none;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .project-details-container img.velvet-size,
  .project-details-container video.velvet-size {
    max-width: 100%;
    max-height: none;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  body.safari .project-details-container img.velvet-size,
  body.safari .project-details-container video.velvet-size {
    transform: scale(1);
  }

  body.safari .project-details-container .image-item img.velvet-size,
  body.safari .project-details-container .image-item video.velvet-size {
    margin-top: 0px;
  }
}

.project-details-container .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-details-container .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.project-details-container .button-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .clients-section {
    flex-direction: column;
  }
}
